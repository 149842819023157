/* body and root styling */
html,
body {
    box-sizing: border-box;
    height: 100%;
    padding: 0px;
    margin: 0px;
}
#root {
    height: 100%;
}
/* end of body and root styling */

/* mapbox Popup styling */
.mapboxgl-popup {
    max-width: unset !important;
}
.mapboxgl-popup-content {
    border-radius: 12px !important;
}
.mapboxgl-popup-close-button {
    font-size: 20px;
    color: #c3c3c3;
}
.mapboxgl-popup-close-button:hover {
    color: #999;
    background-color: transparent !important;
}
/* end of mapbox Popup styling */

/* login page styling */
.login-container {
    height: 100%;
    background-color: #fff;
    z-index: 3;
}
.login-box {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    height: 600px;
    width: 360px;
    margin: auto;
    margin-top: 4em;
    background-color: #fff;
    font-family: Segoe UI;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}
.login-header {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
    color: #3f3f3f;
    font-size: 2em;
}
.login-text {
    font-size: 14px;
    color: #3f3f3f;
    font-weight: 600;
    text-align: left;
    font-family: Segoe UI;
}
.login-buttons {
    text-align: center;
}
.login-button {
    width: 270px !important;
    margin: 1em 0 1em 0 !important;
    font-size: 0.8em !important;
    text-transform: unset !important;
    padding: 0 !important;
}
/* end of login page styling */

/* Loading page styling */
.loading-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
}
.root {
    display: flex;
    align-items: center;
}
.progress-box {
    display: flex;
}
.progress-tasks {
    width: 250px;
    height: 300px;
    color: #8a989e;
    background: #fff;
    padding: 10px;
    border: 2px solid rgba(216, 219, 225, 0.5);
    border-radius: 25px;
}
.wrapper {
    margin: 8px;
    position: relative;
}
.button-progress1 {
    color: #4a94ff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
}
.button-progress2 {
    color: #ffa74f;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
}
.button-progress3 {
    color: #f54ef4;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12;
    margin-left: -12;
}
.button-success1 {
    background-color: #4a94ff !important;
    color: #fff !important;
}
.button-success2 {
    background-color: #ffa74f !important;
    color: #fff !important;
}
.button-success3 {
    background-color: #f54ef4 !important;
    color: #fff !important;
}
/* end of loading page styling */

/* Program Modal styling */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-table {
    background-color: #fff;
    border: 2px solid #dedede;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    min-width: 800px;
    height: 600px;
    overflow: scroll;
}
/* end of program modal styling */

/* Table styling */
.table-wrapper {
    width: 100%;
    height: 100%;
}
/* end of table styling */
