.c1wupbe700-canary38 {
    background-color: #fff !important;
    border-right: 0px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
    font-family: Roboto !important;
    color: rgba(0, 0, 0, 0.87) !important;
    height: auto !important;
}

.rdg {
    background-color: #fff !important;
    border-color: #e0e0e0 !important;
    height: 100% !important;
}

.rdg-cell {
    background-color: #fff !important;
    border-right: 0px solid #e0e0e0 !important;
    border-bottom: 1px solid #e0e0e0 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
}
.r104f42s700-canary38 {
    height: 100% !important;
}
